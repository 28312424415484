import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { useCallback, useEffect, useState } from 'react'

import { GatsbyImageFocused } from '@/features/common/'
import { absoluteFill, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useMissionScrollContext } from '../../contexts/missionScrollContext'

export const ImagesContainer = (): JSX.Element => {
  const { datoCmsMissionPage } =
    useStaticQuery<Queries.MissionImagesContainerQuery>(graphql`
      query MissionImagesContainer {
        datoCmsMissionPage {
          images {
            large: gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { q: 65 }
            )
            medium: gatsbyImageData(
              width: 960
              sizes: "(max-width: 540px) 100vw, 67vw"
              imgixParams: { q: 65 }
            )
            small: gatsbyImageData(
              width: 480
              sizes: "(max-width: 540px) 50vw, 33vw"
              imgixParams: { q: 65 }
            )
            ...ImageFocalData
          }
        }
      }
    `)
  const { images } = datoCmsMissionPage || {}
  const { viewZones } = useMissionScrollContext()
  const viewZone = viewZones.length ? Math.max(...viewZones) : 0

  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => setIsLoaded(true), [])

  const getImageSize = useCallback((i: number) => {
    switch (i) {
      case 0:
      case 10:
        return 'large'
      case 1:
      case 2:
      case 3:
      case 4:
      case 9:
        return 'medium'
      case 5:
      case 6:
      case 7:
      case 8:
      default:
        return 'small'
    }
  }, [])

  const styles = {
    wrapper: css`
      ${absoluteFill}
      clip-path: inset(0 0 0 0);
    `,
    container: css`
      --gtr: 1rem;
      --margin: 1.5rem;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: grid;
      box-sizing: border-box;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(12, 1fr);
      grid-gap: var(--gtr);
      padding: calc(var(--nav-height-min) + var(--margin)) var(--margin)
        var(--margin);
      background: #000;
      transition: background-color 1000ms ease;
      ${viewZone > 8 &&
      css`
        background: ${colors.red};
      `}
      ${viewZone > 19 &&
      css`
        background: #fff;
      `}
      ${viewZone > 22 &&
      css`
        background: #000;
      `}
    `,
    image: css`
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition:
        opacity 1000ms ease,
        clip-path 1000ms ease;
      clip-path: inset(0 0 0 0);
      > div {
        height: 100%;
        transition: filter 1000ms ease;
      }
    `,
    imageByIndex: (i: number) => {
      switch (i) {
        case 0:
          return css`
            grid-column: 1 / -1;
            grid-row: 1 / span 12;
            opacity: 0;
            ${isLoaded &&
            css`
              opacity: 1;
            `}
            ${viewZone > 0 &&
            css`
              opacity: 0.3;
              > div {
                filter: saturate(0);
              }
            `}
            ${viewZone > 2 &&
            css`
              clip-path: inset(0 0 0 100%);
            `}
          `
        case 1:
          return css`
            opacity: 1;
            grid-column: 1 / span 4;
            grid-row: 1 / span 12;
            clip-path: inset(0 100% 0 0);
            ${mq().s} {
              grid-column: 1 / -1;
              grid-row: 1 / span 6;
            }
            ${viewZone > 3 &&
            css`
              clip-path: inset(0 0 0 0);
            `}
            ${viewZone > 6 &&
            css`
              ${mq().s} {
                opacity: 0.3;
                > div {
                  filter: saturate(0);
                }
              }
            `}
            ${viewZone > 7 &&
            css`
              clip-path: inset(0 0 0 100%);
            `}
          `
        case 2:
          return css`
            opacity: 1;
            grid-column: span 8 / -1;
            grid-row: 1 / span 8;
            clip-path: inset(0 0 100% 0);
            ${mq().s} {
              grid-column: 1 / span 6;
              grid-row: 7 / span 6;
              clip-path: inset(0 100% 0 0);
            }
            ${viewZone > 4 &&
            css`
              clip-path: inset(0 0 0 0);
              ${mq().s} {
                clip-path: inset(0 0 0 0);
              }
            `}
            ${viewZone > 6 &&
            css`
              opacity: 0.3;
              > div {
                filter: saturate(0);
              }
            `}
            ${viewZone > 7 &&
            css`
              clip-path: inset(0 0 100% 0);
              ${mq().s} {
                clip-path: inset(100% 0 0 0);
              }
            `}
          `
        case 3:
          return css`
            opacity: 1;
            grid-column: span 8 / -1;
            grid-row: 9 / span 4;
            clip-path: inset(100% 0 0 0);
            ${mq().s} {
              grid-column: span 6 / -1;
              grid-row: 7 / span 6;
            }
            ${viewZone > 5 &&
            css`
              clip-path: inset(0 0 0 0);
            `}
            ${viewZone > 6 &&
            css`
              opacity: 0.3;
              > div {
                filter: saturate(0);
              }
            `}
            ${viewZone > 7 &&
            css`
              clip-path: inset(100% 0 0 0);
            `}
          `
        case 4:
          return css`
            opacity: 1;
            grid-column: 1 / span 6;
            grid-row: 1 / span 6;
            clip-path: inset(0 100% 0 0);
            ${viewZone > 9 &&
            css`
              clip-path: inset(0 0 0 0);
            `}
            ${viewZone > 12 &&
            (viewZone < 14 || viewZone > 17) &&
            css`
              opacity: 0.2;
              > div {
                filter: saturate(0) contrast(2);
              }
            `}
            ${viewZone > 18 &&
            css`
              clip-path: inset(0 100% 0 0);
            `}
            ${mq().s} {
              grid-column: 1 / -1;
              grid-row: 1 / span 4;
              clip-path: inset(0 100% 0 0);
              ${viewZone > 11 &&
              css`
                clip-path: inset(0 0 0 0);
              `}
              ${viewZone > 12 &&
              css`
                opacity: 0.2;
                > div {
                  filter: saturate(0) contrast(2);
                }
              `}
              ${viewZone > 13 &&
              css`
                clip-path: inset(0 0 0 100%);
              `}
            }
          `
        case 5:
          return css`
            opacity: 1;
            grid-column: 7 / span 3;
            grid-row: 1 / span 6;
            clip-path: inset(0 0 100% 0);
            ${viewZone > 10 &&
            css`
              clip-path: inset(0 0 0 0);
            `}
            ${viewZone > 17 &&
            css`
              opacity: 0.2;
              > div {
                filter: saturate(0) contrast(2);
              }
            `}
            ${viewZone > 18 &&
            css`
              clip-path: inset(0 0 100% 0);
            `}
            ${mq().s} {
              grid-column: 1 / span 6;
              grid-row: 1 / span 4;
              clip-path: inset(0 100% 0 0);
              ${viewZone > 15 &&
              css`
                clip-path: inset(0 0 0 0);
              `}
              ${viewZone > 16 &&
              css`
                opacity: 0.2;
                > div {
                  filter: saturate(0) contrast(2);
                }
              `}
              ${viewZone > 18 &&
              css`
                clip-path: inset(0 100% 0 0);
              `}
            }
          `
        case 6:
          return css`
            opacity: 1;
            grid-column: 10 / span 3;
            grid-row: 1 / span 6;
            clip-path: inset(0 0 0 100%);
            ${viewZone > 11 &&
            css`
              clip-path: inset(0 0 0 0);
            `}
            ${viewZone > 17 &&
            css`
              opacity: 0.2;
              > div {
                filter: saturate(0) contrast(2);
              }
            `}
            ${viewZone > 18 &&
            css`
              clip-path: inset(0 0 0 100%);
            `}
            ${mq().s} {
              grid-column: span 6 / -1;
              grid-row: 1 / span 4;
              clip-path: inset(0 0 0 100%);
              ${viewZone > 15 &&
              css`
                clip-path: inset(0 0 0 0);
              `}
              ${viewZone > 16 &&
              css`
                opacity: 0.2;
                > div {
                  filter: saturate(0) contrast(2);
                }
              `}
              ${viewZone > 18 &&
              css`
                clip-path: inset(0 0 0 100%);
              `}
            }
          `
        case 7:
          return css`
            opacity: 1;
            grid-column: 1 / span 3;
            grid-row: 7 / span 6;
            clip-path: inset(0 100% 0 0);
            ${viewZone > 11 &&
            css`
              clip-path: inset(0 0 0 0);
            `}
            ${viewZone > 12 &&
            css`
              opacity: 0.2;
              > div {
                filter: saturate(0) contrast(2);
              }
            `}
            ${viewZone > 18 &&
            css`
              clip-path: inset(0 100% 0 0);
            `}
            ${mq().s} {
              grid-column: 1 / -1;
              grid-row: 9 / span 4;
              clip-path: inset(0 100% 0 0);
              ${viewZone > 9 &&
              css`
                clip-path: inset(0 0 0 0);
              `}
              ${viewZone > 12 &&
              css`
                opacity: 0.2;
                > div {
                  filter: saturate(0) contrast(2);
                }
              `}
              ${viewZone > 13 &&
              css`
                clip-path: inset(0 0 0 100%);
              `}
            }
          `
        case 8:
          return css`
            opacity: 1;
            grid-column: 4 / span 3;
            grid-row: 7 / span 6;
            clip-path: inset(100% 0 0 0);
            ${viewZone > 10 &&
            css`
              clip-path: inset(0 0 0 0);
            `}
            ${viewZone > 12 &&
            css`
              opacity: 0.2;
              > div {
                filter: saturate(0) contrast(2);
              }
            `}
            ${viewZone > 18 &&
            css`
              clip-path: inset(100% 0 0 0);
            `}
            ${mq().s} {
              grid-column: 1 / -1;
              grid-row: 5 / span 4;
              clip-path: inset(0 0 0 100%);
              ${viewZone > 10 &&
              css`
                clip-path: inset(0 0 0 0);
              `}
              ${viewZone > 12 &&
              css`
                opacity: 0.2;
                > div {
                  filter: saturate(0) contrast(2);
                }
              `}
              ${viewZone > 13 &&
              css`
                clip-path: inset(0 100% 0 0);
              `}
            }
          `
        case 9:
          return css`
            opacity: 1;
            grid-column: 7 / span 6;
            grid-row: 7 / span 6;
            clip-path: inset(100% 0 0 0);

            ${viewZone > 8 &&
            css`
              clip-path: inset(0 0 0 0);
            `}
            ${viewZone > 16 &&
            css`
              opacity: 0.2;
              > div {
                filter: saturate(0) contrast(2);
              }
            `}
            ${viewZone > 18 &&
            css`
              clip-path: inset(100% 0 0 0);
            `}          
            ${mq().s} {
              grid-column: 1 / -1;
              grid-row: 5 / 13;
              clip-path: inset(100% 0 0 0);
              ${viewZone > 14 &&
              css`
                clip-path: inset(0 0 0 0);
              `}
              ${viewZone > 16 &&
              css`
                opacity: 0.2;
                > div {
                  filter: saturate(0) contrast(2);
                }
              `}
              ${viewZone > 18 &&
              css`
                clip-path: inset(100% 0 0 0);
              `}
            }
          `
        case 10:
          return css`
            grid-column: 1 / -1;
            grid-row: 1 / span 12;
            opacity: 1;
            clip-path: inset(50% 0 50% 0);
            ${viewZone > 19 &&
            css`
              clip-path: inset(0 0 0 0);
            `}
            ${viewZone > 20 &&
            css`
              opacity: 0.3;
              > div {
                filter: saturate(0) contrast(0.75);
              }
            `}
            ${viewZone > 21 &&
            css`
              clip-path: inset(0 0 100% 0);
            `}
          `
      }
    },
  }
  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        {images?.map((image, i) => {
          return (
            <div
              key={i}
              css={[styles.image, () => styles.imageByIndex(i)]}
            >
              <GatsbyImageFocused
                loading={i === 0 ? 'eager' : 'lazy'}
                image={image[getImageSize(i)]}
                data={image}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
